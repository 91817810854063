
<script setup>
// eslint-disable-next-line no-unused-vars
import appConfig from "@/app.config";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reactive, onBeforeMount } from 'vue'
import { callApi } from '../../../msal/msalApi'
//import Swal from 'sweetalert2'
import store from '../../../state/store';
//import axios from "axios";
const title = "Dashboard";

import { useRoute } from 'vue-router'

const data = reactive({
    loading: false,
    userEmail: '',
    showLoading: false,
    selectFranchise: '',
    selectLocation: '',
    inputSearch: '',
    totalResults: null,
    selectedMetric: 0,
    items: [],

});


const loadFranchisesData = async () => {
    const countType = data.selectedMetric; // Map selected metric to countType (0, 10, 20)
    const returnTopN = 100; // Assuming you want the top 5 franchises
    try {
        const endPoint = `${process.env.VUE_APP_WLT_API}Dashboard/FranchiseClientCount?countType=${countType}&returnTopN=${returnTopN}`;
        const response = await callApi(endPoint, store.getters.getToken);

        data.items = response
        data.showLoading = true;
        data.loading = true;
    } catch (error) {
        console.error(error);
        // Handle errors appropriately
    }
};
onBeforeMount(() => {
    const route = useRoute();

    route.query.selectedMetric ? data.selectedMetric = route.query.selectedMetric : data.selectedMetric = 0

    loadFranchisesData()
});
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"></PageHeader>
        <h1>Top Licensees</h1>
        <div class="row">
            <div class="col-10 col-md-4 col-sm-10">
                <select v-model="data.selectedMetric" class="form-select text-center border" @change="loadFranchisesData">
                    <option value="20">Top 5 New Clients</option>
                    <option value="0">Top 5 Total Clients</option>
                    <option value="10">Top 5 Active Clients</option>
                </select>
            </div>

            <div class="col-2 col-md-12 col-sm-2">
                <div class="float-end fs-1 text-danger">
                    <router-link class="fs-1 text-danger"
                        :to="{ path: '/', query: { 'selectedMetric': data.selectedMetric } }"><i style="font-size: 3rem;"
                            class='bx bx-x'></i></router-link>
                </div>
            </div>
        </div>

        <div class="spinner-overlay text-center mt-5" v-if="!data.showLoading">
            <div class="spinner-border text-primary  custom-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div>Loading...</div>
        </div>

        <table v-else class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Franchise</th>
                    <th scope="col">Count</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Postal Code</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="(franchises, index) in data.items" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                        <router-link class="fs-5"
                            :to="{ path: '/', query: { selectFranchise: franchises.franchise_ID } }">{{
                                franchises.franchiseName }}</router-link>

                    </td>
                    <td>
                        <router-link class="fs-5"
                            :to="{ path: '/client', query: { selectFranchise: franchises.franchise_ID } }">{{ franchises.totalCount
                            }}</router-link>

                    </td>
                    <td>{{ franchises.city }}</td>
                    <td>{{ franchises.state }}</td>
                    <td>{{ franchises.postalCode }}</td>

                </tr>
            </tbody>
        </table>

    </Layout>
</template>



<style lang="scss" scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    animation: spin 1s linear infinite;
}

.spinner-border.custom-spinner {
    width: 6rem;
    height: 6rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-message {
    margin-top: 10px;
}

.spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
</style>